<template>
  <b-container class="mr-0" style="padding: 0;">
    <b-form-row style="margin-bottom: -15px">
      <b-col>
        <legend class="col-form-label">{{ $t_netdb(object_attribute.description_global_scope) }}</legend>
      </b-col>
      <b-col cols="1">
        <b-button variant="outline-success" @click="true_values.push('')">
          <netvs-icon icon="add_item"></netvs-icon>
        </b-button>
      </b-col>
    </b-form-row>
    <b-form-row style="margin-bottom: -5px" v-for="(v, index) in true_values" :key="v">
      <b-col>
        <APIObjectDataEditField :object_attribute="object_attribute" :attribute_preset="v"
                                :attribute_reference_name="attribute_reference_name"
                                :attribute_referencing="attribute_referencing"
                                :attribute_name="attribute_name"
                                :attribute_non_expert_nullable="attribute_non_expert_nullable"
                                :input_reducer="input_reducer"
                                :show_description="(index+1) === true_values.length"
                                :show_label="false"
                                v-bind:key="`${attribute_name}${index}`"></APIObjectDataEditField>
      </b-col>
      <b-col cols="1">
        <b-button variant="outline-danger" @click="true_values.splice(index, 1)" :disabled="true_values.length == 0">
          <netvs-icon icon="delete"></netvs-icon>
        </b-button>
      </b-col>
    </b-form-row>
  </b-container>
</template>

<script>
import APIObjectDataEditField from '@/components/db-editor/APIObjectDataEditField.vue'

export default {
  name: 'APIObjectDataEditFieldList',
  components: {APIObjectDataEditField},
  methods: {},
  data: function () {
    return {
      true_values: this.attribute_preset !== undefined ? this.attribute_preset : []
    }
  },
  watch: {
    attribute_preset(val) {
      if (typeof val === 'string') {
        this.true_values = [val]
        return
      }
      if (val === undefined) {
        this.true_values = []
        return
      }
      this.true_values = val
    }
  },
  props: {
    object_attribute: {
      required: true
    },
    attribute_non_expert_nullable: {
      required: false,
      default() {
        return false
      }
    },
    attribute_referencing: null,
    attribute_reference_name: null,
    attribute_preset: null,
    attribute_name: {
      type: String,
      required: true
    },
    input_reducer: {
      default() {
        return null
      }
    },
  }
}
</script>

<style scoped>

</style>
