<template>
  <div v-if="object_attribute">
    <b-form-group
        :title="`Name: ${attribute_name}\nReference: ${attribute_reference_name}\nReferencing: ${attribute_referencing}`"
        :description="show_description?$t_netdb(object_attribute.description_detail):undefined">
      <template v-slot:label v-if="show_label">
        <netvs-icon icon="transaction_api_object"/>
        {{ $t_netdb(object_attribute.description_global_scope) }}
      </template>
      <b-form-input :value="attribute_preset || ''"
                    v-if="(input_reducer === null || input_reducer instanceof Boolean) && (object_attribute.data_type.includes('integer'))"
                    type="number"
                    :name="attribute_name"
                    :disabled="(input_reducer === false && !this.$store.state.expert) || make_null"
      ></b-form-input>
      <template
          v-else-if="object_attribute.data_type==='timestamp_tz'">
        <b-row>
          <b-col>
            <b-form-datepicker :label-reset-button="$t('components.data_edit_field.remove_date')" :reset-value="null"
                               reset-button
                               @input="composeDate()" v-model="date_picker"
                               locale="de"></b-form-datepicker>
          </b-col>
          <b-col>
            <b-form-timepicker reset-value="00:00" reset-button @input="composeDate()" v-model="time_picker"
                               locale="de"></b-form-timepicker>
          </b-col>
        </b-row>
        <input type="hidden" :value="composedDate" :name="attribute_name"/>
      </template>
      <b-input-group v-else-if="object_attribute.data_type=='boolean'">
        <b-checkbox :checked="attribute_preset || false"
                    :unchecked-value="false"
                    v-model="checkbox_checked"
                    :disabled="(input_reducer instanceof Boolean && !input_reducer && !this.$store.state.expert) || make_null"></b-checkbox>
        <input :name="attribute_name" :value="checkbox_checked.toString()" type="hidden"/>
      </b-input-group>
      <b-form-select :name="attribute_name" v-else-if="input_reducer instanceof Object" :value="attribute_preset">
        <template v-slot:first>
          <b-form-select-option :value="null" disabled>-- {{ $t('components.data_edit_field.make_a_choice') }} --
          </b-form-select-option>
        </template>
        <b-form-select-option v-for="(data,key) in input_reducer" :key="key" :value="key">{{
            data.display_name
          }}
        </b-form-select-option>
      </b-form-select>
      <b-form-input v-else v-model="data"
                    :name="attribute_name"
                    :disabled="(input_reducer === false && !this.$store.state.expert) || make_null"
                    :state="state" @update="check_input_by_datatype"
      ></b-form-input>
      <input type="hidden" v-if="input_reducer === false" :value="attribute_preset || ''" :name="attribute_name"/>
      <b-checkbox :name="attribute_name+'_null'" v-model="make_null"
                  v-if="($store.state.expert || attribute_non_expert_nullable) && object_attribute.new.is_nullable">
        {{ $t('system.null') }}
        <NetvsExpertMarker v-if="!attribute_non_expert_nullable"/>
      </b-checkbox>
    </b-form-group>
  </div>
</template>

<script>

import Typechecker from '@/util/typechecker'
import NetvsExpertMarker from '@/components/NETVSExpertMarker.vue'

export default {
  name: 'APIObjectDataEditField',
  components: {NetvsExpertMarker},
  data() {
    return {
      data: this.attribute_preset || '',
      date_picker: null,
      time_picker: '00:00',
      composedDate: '__NULL',
      make_null: false,
      state: null,
      checkbox_checked: false,
    }
  },
  watch: {
    $props: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.object_attribute && this.object_attribute.data_type === 'timestamp_tz' && this.attribute_preset !== '' && this.attribute_preset !== null && this.attribute_preset !== '__NULL') {
          const d = new Date(this.attribute_preset)
          this.date_picker = d.getFullYear() + '-' + (d.getMonth() + 1 + '').padStart(2, '0') + '-' + (d.getDate() + '').padStart(2, '0')
          this.time_picker = (d.getHours() + '').padStart(2, '0') + ':' + (d.getMinutes() + '').padStart(2, '0')
          this.composeDate()
        }
      }
    }
  },
  created() {
    if (this.attribute_preset && this.attribute_preset !== 'false') {
      this.checkbox_checked = true
    }
  },
  methods: {
    async check_input_by_datatype() {
      this.state = (await Typechecker.check_input_by_datatype(this, this.object_attribute.data_type, this.data)) ? null : false
    },
    composeDate() {
      if (this.date_picker === null || this.date_picker === '') {
        this.composedDate = '__NULL'
        return
      }
      if (this.time_picker === null || this.time_picker === '' || this.time_picker.toLowerCase().includes('nan')) {
        this.composedDate = new Date(this.date_picker + 'T00:00:00').toJSON()
        return
      }
      this.composedDate = new Date(this.date_picker + 'T' + this.time_picker).toJSON()
    }
  },
  props: {
    object_attribute: {
      required: true
    },
    attribute_non_expert_nullable: {
      required: false,
      default() {
        return false
      }
    },
    attribute_referencing: null,
    attribute_reference_name: null,
    attribute_preset: null,
    attribute_name: {
      type: String,
      required: true
    },
    input_reducer: {
      default() {
        return null
      }
    },
    show_description: {
      type: Boolean,
      default() {
        return true
      }
    },
    show_label: {
      type: Boolean,
      default() {
        return true
      }
    },
  }
}
</script>

<style scoped>

</style>
