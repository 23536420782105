<template>
  <b-navbar toggleable="lg" fixed="top" type="light"
            variant="light"
            class="navbar">
    <!-- Brand and toggle get grouped for better mobile display -->
    <b-navbar-brand to="/">
      <img v-if="$store.state.spec_ready" class="d-inline-block align-top scc-img" alt="SCC"
           src="/logo.webp"/>
      <img v-else class="d-inline-block align-top scc-img" alt="NETTVS" src="@/assets/img/netvs_logo.png"/>
    </b-navbar-brand>
    <b-nav-text class="ml-3 text-danger" v-if="!$store.state.netvs_online">
      <netvs-icon size="1x" class="pr-1 text-danger" :beat_fade="true" icon="offline"/>
      {{ $t('system.offline') }}
    </b-nav-text>
    <template v-if="$store.state.impersonate_user">
      <b-nav-text class="ml-3">
        <netvs-icon size="1x" class="pr-1 text-danger" :beat_fade="true" icon="warning"/>
        {{ $t('system.impersonating') }}: <strong class="text-danger">{{ $store.state.impersonate_user }}</strong>
        <netvs-icon size="1x" class="pl-2 pr-2 text-danger" :beat_fade="true" icon="warning"/>
      </b-nav-text>
      <b-button variant="outline-secondary" class="nav-link" :id="'stop-impersonate'"
                @click="$store.commit('updateImpersonatingUser', null); $store.commit('reloadRouterComp')">
        <netvs-icon icon="depersonate"></netvs-icon>
      </b-button>
      <b-tooltip :target="'stop-impersonate'" triggers="hover" variant="secondary" placement="bottom">
        {{ $t('components.svc_card.depersonate') }}
      </b-tooltip>
    </template>
    <b-navbar-toggle target="navbarNavDropdown">
      <netvs-icon icon="mobile_menu_sandwich"></netvs-icon>
    </b-navbar-toggle>
    <!-- Collect the nav links, forms, and other content for toggling -->
    <b-collapse is-nav id="navbarNavDropdown" ref="navdropdown" v-model="navbar_collapse_open">
      <b-navbar-nav v-if="!$store.state.sysinfo.host_oper_mode.is_test">
        <b-nav-item target="_blank" :href="`https://netvs-test.scc.kit.edu${$route.path}`">
          <netvs-icon icon="test" :class="{wideIcon: navbar_collapse_open}"></netvs-icon>
          {{ $t('system.goto_test') }}
          <netvs-icon icon="navigate"/>
        </b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-else>
        <b-nav-item target="_blank" :href="`https://netvs.scc.kit.edu${$route.path}`">
          <netvs-icon icon="prod" :class="{wideIcon: navbar_collapse_open}"></netvs-icon>
          {{ $t('system.goto_prod') }}
          <netvs-icon icon="navigate"/>
        </b-nav-item>
      </b-navbar-nav>
      <Navigation class="d-lg-none" hide-popovers/>
      <b-popover v-if="!isMobile()" target="alerts_button" placement="bottomleft" triggers="hover focus">
        <template #title>{{ $t('system.alerts') }}</template>
        <div v-if="$store.state.maint_alerts.length + $store.state.sys_alerts.length === 0"
             class="text-muted text-center">
          <netvs-icon icon="all_good" size="4x"></netvs-icon>
          <br/>
          <h4>{{ $t('system.all_good') }}</h4>
        </div>
        <SystemAlertDisplay></SystemAlertDisplay>
      </b-popover>
      <b-modal v-if="isMobile()" id="system-alerts-modal" :title="this.$t('system.alerts')" scrollable ok-only>
        <div v-if="$store.state.maint_alerts.length + $store.state.sys_alerts.length === 0"
             class="text-muted text-center">
          <netvs-icon icon="all_good" size="4x"></netvs-icon>
          <br/>
          <h4>{{ $t('system.all_good') }}</h4>
        </div>
        <SystemAlertDisplay></SystemAlertDisplay>
      </b-modal>
      <b-navbar-nav class="ml-auto">
        <b-nav-item id="alerts_button" v-b-modal="'system-alerts-modal'">
          <netvs-icon icon="alerts" :class="{wideIcon: navbar_collapse_open}"
                      :fade="$store.state.maint_alerts.length + $store.state.sys_alerts.length > 0"></netvs-icon>
          {{ navbar_collapse_open ? $t('system.alerts') : '' }}
          <b-badge class="ml-1"
                   :variant="$store.state.maint_alerts.length + $store.state.sys_alerts.length === 0 ? 'success' : 'danger'">
            {{ $store.state.maint_alerts.length + $store.state.sys_alerts.length }}
          </b-badge>
        </b-nav-item>
        <b-nav-item active @click.stop="$root.$emit('show_search')" id="navbar-search-button">
          <netvs-icon icon="search" :class="{wideIcon: navbar_collapse_open}"></netvs-icon>
          {{ $t('system.search') }} & {{ $t('system.quick_navigation') }}
        </b-nav-item>
        <b-tooltip target="navbar-search-button" triggers="hover" container="null" delay="1000">
          <span style="font-family: monospace, monospace;">{{ $t('components.navbar.search_hint') }}</span>
        </b-tooltip>
        <b-nav-item :active="$store.state.show_sidebar_right" v-if="$store.state.token"
                    @click.stop="$store.commit('showSidebarRight', !$store.state.show_sidebar_right)">
          <netvs-icon icon="transaction_list" :class="{wideIcon: navbar_collapse_open}"></netvs-icon>
          {{ $t('system.planned_actions') }}
          <b-badge variant="primary">{{ $store.state.ta_list ? $store.state.ta_list.length : '0' }}</b-badge>
        </b-nav-item>
        <b-nav-item-dropdown v-if="$store.state.user" right
                             :toggle-class="$route.path.startsWith('/user/')?'active':''">
          <template slot="button-content">
            <netvs-icon :icon="usr_ico" :class="{wideIcon: navbar_collapse_open}"></netvs-icon>
            <span v-if="$store.state.user"> {{ $store.state.user.login_name }}
              <netvs-icon size="1x" class="pr-1 text-danger" :beat_fade="true" v-if="$store.state.impersonate_user"
                          icon="warning"/>
            </span>
          </template>
          <template v-if="$store.state.user">
            <b-dropdown-item v-if="$store.state.token" to="/user/tokens" :active="$route.path === '/user/tokens'">
              <netvs-icon icon="token"/>
              {{ $tc('system.subaccount', 2) }} & {{ $tc('system.api_token', 2) }}
            </b-dropdown-item>
            <b-dropdown-divider v-if="$store.state.token"/>
            <b-dropdown-item @click="logout">
              <netvs-icon fixed-width icon="sign_out"/>
              {{ $t('system.logout') }}
            </b-dropdown-item>
            <b-dropdown-item @click="global_logout">
              <netvs-icon fixed-width icon="sign_out"/>
              {{ $t('system.global_logout') }}
            </b-dropdown-item>
          </template>
        </b-nav-item-dropdown>
        <b-nav-item v-else to="/login">
          <netvs-icon icon="sign_in"/>
          <span> {{ $t('system.login') }}</span>
        </b-nav-item>
        <b-nav-item-dropdown right :title="$t('components.navbar.local_application_settings')">
          <template v-slot:button-content>
            <netvs-icon icon="settings" :class="{wideIcon: navbar_collapse_open}"/>
            <template v-if="navbar_collapse_open"> {{ $t('components.navbar.local_application_settings') }}</template>
          </template>
          <b-dropdown-item @click="$store.commit('toggle_expert')">
            <b-checkbox :checked="$store.state.expert">
              <netvs-icon icon="expert_feature"></netvs-icon>
              {{ $t('components.navbar.expert_mode') }}
            </b-checkbox>
          </b-dropdown-item>
          <b-dropdown-item @click="$store.commit('toggle_keep_ta')">
            <b-checkbox :checked="$store.state.keep_ta">
              <netvs-icon icon="transaction_list"></netvs-icon>
              {{ $t('components.navbar.keep_transaction_list_after_logout') }}
            </b-checkbox>
          </b-dropdown-item>
          <b-dropdown-item @click="$store.commit('toggle_developer')">
            <b-checkbox :checked="$store.state.developer">
              <netvs-icon icon="development_feature"></netvs-icon>
              {{ $t('components.navbar.developer_mode') }}
            </b-checkbox>
          </b-dropdown-item>
          <b-dropdown-item v-if="$store.state.developer" @click="$store.commit('toggle_db_editor_cache')">
            <b-checkbox :checked="$store.state.enable_db_editor_cache">
              <netvs-icon icon="development_feature"></netvs-icon>
              {{ $t('components.navbar.db_editor_cache') }}
            </b-checkbox>
          </b-dropdown-item>
          <b-dropdown-item @click="$store.commit('updateDefaultLoginPage', $router.currentRoute.path)">
            <netvs-icon class="mr-1" icon="home"></netvs-icon>
            {{ $t('components.navbar.set_as_default_page') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <LocaleSwitcher :navbar_collapse_open="navbar_collapse_open"/>
      </b-navbar-nav>
    </b-collapse><!-- /.navbar-collapse -->
  </b-navbar>
</template>

<script>
import Navigation from '@/components/Navigation'
import LocaleSwitcher from '@/components/LocaleSwitcher'
import SystemAlertDisplay from '@/components/SystemAlertDisplay.vue'

export default {
  name: 'TopNavbar',
  components: { SystemAlertDisplay, LocaleSwitcher, Navigation },
  data() {
    return {
      usr_ico: 'user',
      ms: 0,
      pev: null,
      cuv: null,
      navbar_collapse_open: false
    }
  },
  methods: {
    async logout() {
      await this.$store.dispatch('doLogout')
      try {
        await this.$router.push('/login')
      } catch (e) {
        // user probably was on /login and routing there again causes an error
      }
    },
    async global_logout() {
      await this.$store.dispatch('doLogout')
      window.location.href = 'https://idp.scc.kit.edu/idp/profile/Logout'
    }
  },
  mounted() {
    const self = this
    document.documentElement.onmousemove = function (event) {
      self.cuv = event
    }
    const intv = setInterval(function () {
      if (self.pev && self.cuv) {
        const movementX = Math.abs(self.cuv.screenX - self.pev.screenX)
        const movementY = Math.abs(self.cuv.screenY - self.pev.screenY)
        const movement = Math.sqrt(movementX * movementX + movementY * movementY)
        const speed = 10 * movement
        if (speed > self.ms) {
          self.ms = speed
        }
      }
      self.pev = self.cuv
      if (self.ms > 10000) {
        self.usr_ico = 'user_ninja'
        clearInterval(intv)
      }
    }, 100)
  }
}
</script>

<style lang="scss" scoped>
@import '../assets/css/variables.scss';

.navbar {
  min-height: $navbar-height;
}

.navbar.navbar-dark.bg-dark,
.navbar.navbar-light.bg-light {
  background-color: $navbar-bg-color !important;
}

@include media-breakpoint-between(xs, md) {
  .navbar {
    max-height: 80vh;
    overflow-y: scroll;
  }
}

.scc-img {
  height: $navbar-height - 20px;
}

.wideIcon {
  width: $left-sidebar-hidden-width - 7px;
  text-align: center;
  display: inline-block;
}

</style>
